import {store} from "../../../../../store/index";
import Api from "../../../../../store/Services/Api";

class StockService{
    getStockDetails(queryParams){
        const params ={
            'branch':queryParams.branch,
            'stock-type': queryParams.stock_type,
            'barcode': queryParams.barcode,
            'searched':queryParams.searched,
            'page-reload':queryParams.page_reload,
            'offset':queryParams.offset
        }
        const url = 'api/stock-details'
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }

    getBranches(){
        const url = 'api/stock/branches'
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    getSpecificItemDetails(id){
        const url = `api/stock-details/item/${id}`
        const headers = {
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }

    editItemDetails(id, data){
        const url = `api/stock-details/item/${id}`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,data,headers);
    }
}

export default new StockService();
<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Inventory</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Stock Detail
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Stock Detail</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <div class="col-6 tx-right">
        <router-link
          v-if="checkSinglePermission('create_opening_stock')"
          :to="{ name: 'new-opening-stock' }"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          tag="button"
          >New Opening Stock</router-link
        >
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-9 d-block mr-0 pr-0">
          <select
            v-model="filterParams.pageIndex"
            @change="selectTotalData"
            class="mr-2 mg-t-5"
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value>All</option>
          </select>
          <select
            v-model="filterParams.branch"
            class="filter-input mr-2 mg-t-5"
            @change="selectBranch"
          >
            <option value>All Branch</option>
            <option
              v-for="(branch, index) in dataSets.branches"
              :key="index"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
          <select
            v-model="filterParams.stock_type"
            class="filter-input mr-2 mg-t-5"
            @change="selectStockType"
          >
            <option value>All Stock</option>
            <option value="1">In-House Stock</option>
            <option value="2">Transferring Stock</option>
          </select>
          <input
            type="text"
            v-model="filterParams.barcode"
            class="mg-t-5 mr-2"
            placeholder="Search by Barcode"
            @blur="searchedBarcode"
          />
        </div>
        <div class="col-md-3 ml-0 pl-0 mg-t-5">
          <input
            type="text"
            v-model="filterParams.searched"
            class="float-right"
            placeholder="Search..."
            @keyup="searchedKeyword"
          />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Item Code</th>
              <th :class="!filterParams.stock_type ? 'wd-45' : 'wd-25'">
                Item Name
              </th>
             <th class="wd-15p" v-if="authData.item_setting.extra_detail==1">Extra Details</th>
              <th class="wd-20p">Category</th>
              <th class="wd-10p">Stock Quantity</th>
              <th class="wd-10p" v-if="!filterParams.stock_type">
                Sales Order
              </th>
              <th class="wd-10p" v-if="!filterParams.stock_type">
                Purchase Order
              </th>
              <th class="wd-10p no-print text-center">Action</th>
            </tr>
          </thead>
          <tbody v-if="!loading && dataSets.items.length > 0">
            <tr v-for="(stock, index) in dataSets.items" :key="index">
              <th scope="row" class="table-start-item">
                {{ filterParams.pageIndex * (page - 1) + ++index }}
              </th>
              <td>{{ stock.item_code }}</td>
              <td>{{ stock.name }}</td>
               <td v-if="authData.item_setting.extra_detail==1"><div v-if="stock.extra_details.length > 0">
                  <div v-for="(detail) in stock.extra_details" :key="detail.id">
                    {{detail.title}} - {{detail.value}}<br/>
                  </div>
                </div>
                <div v-else>-</div></td>
              <td>{{ stock.item_group }}</td>
              <td>
                <span v-if="stock.total_stock"
                  >{{ stock.total_stock }} {{ stock.utitle }}</span
                >
                <span v-else>0</span>
              </td>
              <td v-if="!filterParams.stock_type">
                <span v-if="stock.total_order"
                  >{{ stock.total_order }} {{ stock.utitle }}</span
                >
                <span v-else>0</span>
              </td>
              <td v-if="!filterParams.stock_type">
                <span v-if="stock.total_purchase_order">
                  {{ stock.total_purchase_order }}
                  {{ stock.utitle }}
                </span>
                <span v-else>0</span>
              </td>
              <td class="text-center no-print">
                <a
                  href="javascript:;"
                  class="mr-3"
                  title="View Purchase Details"
                  @click="
                    view(
                      'moreDetail',
                      stock.item_id,
                      stock.name,
                      stock.item_group,
                      stock.utitle,
                      stock.item_code
                    )
                  "
                >
                  <i class="fas fa-eye tx-success"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td colspan="11" style="text-align: center">
                {{ errorMessage }}
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && dataSets.items.length == 0">
            <tr>
              <td colspan="11" style="text-align: center">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align: center">Loading.....</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <MoreDetail v-if="item.id" />
    <Paginate
      v-model="page"
      :pageCount="totalPageCount"
      :containerClass="'pagination'"
      :clickHandler="clickCallback"
      v-if="totalPageCount > 1"
    />
  </div>
</template>
<script>
import MoreDetail from "./details";
import { mapGetters } from "vuex";
import Services from "./Services/Service";
import _ from "lodash";
import Paginate from "vuejs-paginate";

export default {
  components: {
    MoreDetail,
    Paginate,
  },
  data() {
    return {
      orderStatus: 1,
      page: 1,
      pageReload: "",
      pageSerialNo: 1,
      error: false,
      loading: true,
      errorMessage: "",
      totalPageCount: 0,
      allData: [],
      filterParams: {
        pageIndex: 10,
        barcode: "",
        branch: "",
        stock_type: "",
        searched: "",
        offset: 0,
      },
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists2",
      "authData",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
    ...mapGetters("stockDetails", [
      "dataSets",
      "item",
      "itemDetails",
      "componentClicked",
    ]),
  },
  beforeMount() {
    this.pageReload = true;
    this.getBranches();
    this.getData();
  },
  methods: {
    // pagination clicked
    clickCallback: function (page) {
      let sliceOffset = 0;
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.filterParams.pageIndex + 1;
        sliceOffset = this.filterParams.pageIndex * (page - 1);
      }
      this.offset = (page - 1) * this.filterParams.pageIndex;
      let spliceData = this.allData;
      let currentPageData = spliceData.slice(
        sliceOffset,
        this.filterParams.pageIndex * page
      );
      let totalLength = this.allData.length;
      this.totalPageCount = Math.ceil(totalLength / this.filterParams.pageIndex);
      this.$store.commit("stockDetails/setDataSetsItems", currentPageData);
    },
    view(modal, id, iName, iCategory, unitType,item_code) {
      this.$store.commit("stockDetails/setComponentClicked", true);
      this.$store.commit("setDataId", id);
      let item = {
        id: id,
        item_code:item_code,
        name: iName,
        group: iCategory,
        unit_type: unitType,
      };
      this.$store.commit("stockDetails/setItem", item);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
      Services.getSpecificItemDetails(id)
        .then((response) => {
          this.$store.commit("stockDetails/setItemDetails", response.data.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.commit("stockDetails/setComponentClicked", false);
        });
    },
    selectTotalData() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      // this.getData();
      this.clickCallback(this.page);
    },
    selectBranch() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;

      this.getData();
    },
    selectStockType() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;

      this.getData();
    },
    searchedBarcode() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;

      this.getData();
    },

    //################ Searched Keyword ##################
    searchedKeyword: _.debounce(function () {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      // if (this.filterParams.searched) {
      //   const likeRegExp = new RegExp(this.filterParams.searched, "i");
      //   let filteredData = this.dataSets.items.filter((itm) =>
      //     likeRegExp.test(itm.name)
      //   );
      //   let totalLength = filteredData.length;
      //   this.totalPageCount = totalLength / this.filterParams.pageIndex;
      //   this.allData = filteredData;
      //   let spliceData = this.allData;
      //   let currentPageData = spliceData.slice(0, this.filterParams.pageIndex);
      //   this.$store.commit("stockDetails/setDataSetsItems", currentPageData);
      // } else {
      this.getData();
      // }
    }, 500),
    getData() {
      this.loading = true;
      this.error = false;
      Services.getStockDetails(this.filterParams)
        .then((response) => {
          let totalLength = response.data.data.length;
          this.totalPageCount = Math.ceil(totalLength / this.filterParams.pageIndex);
          this.allData = response.data.data;
          let spliceData = this.allData;
          let currentPageData = spliceData.slice(
            0,
            this.filterParams.pageIndex
          );
          this.$store.commit("stockDetails/setDataSetsItems", currentPageData);
        })
        .catch((error) => {
          this.$store.commit("stockDetails/setDataSetsItems", []);
          if (
            !Object.prototype.hasOwnProperty.call(
              error,
              "response.data.error_message"
            )
          ) {
            if (error.response.data.error_message == "Access Denied.") {
              this.errorMessage = "Access Denied !!!";
            } else {
              this.errorMessage = "Error fetching data from the server.";
            }
          }
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getBranches() {
      Services.getBranches().then((response) => {
        this.$store.commit(
          "stockDetails/setDataSetsBranches",
          response.data.data
        );
      });
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-12">
      <div
        class="modal fade modal_cust show"
        v-if="modalId == 'moreDetail'"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle1"
        style="display: block"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal_ac_head modal-max-80p"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalCenterTitle">
                Stock Detail
              </h5>
              <a
                class="close"
                @click="$store.dispatch('modalClose', 'addNewItem')"
                title="Close"
              >
                <span aria-hidden="true">×</span>
              </a>
            </div>
            <div class="bg-theam-secondary pb-2">
              <div class="row pt-2 pl-2 pr-2">
                <div class="col-md-3 d-flex">
                  <label class="mb-0">Item Code:</label>
                  <div class="stock-header">{{ item.item_code }}</div>
                </div>
                <div class="col-md-5 d-flex">
                  <label class="mb-0">Item Name:</label>
                  <div class="stock-header">{{ item.name }}</div>
                </div>
                <div class="col-md-4 d-flex">
                  <label class="mb-0">Item Category:</label>
                  <div class="stock-header">{{ item.group }}</div>
                </div>
              </div>
              <hr class="mt-1 mb-0 mx-2" />
              <div v-if="componentClicked" class="tx-center">Loading....</div>
              <div
                v-for="(detail, index) in itemDetails"
                :key="index"
              >
                <div class="m-0 p-0" v-if="detail.mergedItems.length > 0">
                  <div class="d-flex mx-2 mt-1">
                    <label class="mb-0">Branch:</label>
                    <div class="stock-header">{{ detail.name }}</div>
                  </div>
                  <div class="mx-2 my-0 bg-theam-primary">
                    <table class="table table1">
                      <thead>
                        <tr>
                          <th scope class="wd-5p table-start-item">S.N.</th>
                          <th scope class="wd-10p">Prcs. ID</th>
                          <th scope class="wd-15p">Stock Qty</th>
                          <th scope class="wd-15p">Prcs. Rate</th>
                          <th scope class="wd-15p">Sales Rate</th>
                          <th scope class="wd-10p">Discount</th>
                          <th scope class="wd-15p">Net Price</th>
                          <th scope class="wd-10p">Expiry Date</th>
                          <th
                            scope
                            class="wd-5p text-right table-end-item"
                            v-if="checkSinglePermission('edit_purchase')"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(purchase, mIndex) in detail.mergedItems"
                          :key="mIndex"
                        >
                          <th scope="row" class="table-start-item">
                            {{ ++mIndex }}
                          </th>
                          <td>
                            <span v-if="purchase.purchase_id"
                              >{{ purchase.purchase.prefix
                              }}{{ purchase.purchase.purchase_no }}</span
                            >
                          </td>
                          <td>
                            {{ purchase.available }}
                            {{ item.unit_type }}
                          </td>
                          <td>
                            {{
                              parseDigitForSlip(purchase.actual_purchase_rate)
                            }}
                          </td>
                          <td>{{ parseDigitForSlip(purchase.sales_rate) }}</td>
                          <td>
                            {{
                              parseDigitForSlip(
                                purchase.sales_discount_percentage
                              )
                            }}
                            %
                          </td>
                          <td>
                            {{
                              parseDigitForSlip(
                                purchase.sales_rate -
                                  purchase.sales_discount_amount
                              )
                            }}
                          </td>
                          <td>{{ purchase.expiry_date }}</td>
                          <td
                            class="text-right table-end-item"
                            v-if="checkSinglePermission('edit_purchase')"
                          >
                            <a
                              href="javascript:;"
                              class="mr-2"
                              @click="editRate(purchase)"
                            >
                              <i class="fa fa-edit"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                v-if="no_data == 0"
                class="tx-center"
              >
                No Data
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    edit sales rate-->
    <div class="col-md-12">
      <div
        class="modal fade modal_cust show"
        v-if="modalId == 'editRate'"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle1"
        style="display: block"
        aria-hidden="true"
      >
        <div
          class="
            modal-dialog modal-dialog-centered modal_ac_head
            single-column-popup
          "
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                Edit-{{ formData.purchase.prefix
                }}{{ formData.purchase.purchase_no }}
              </h5>
              <a
                class="close"
                @click="$store.dispatch('modalClose')"
                title="Close"
              >
                <span aria-hidden="true">×</span>
              </a>
            </div>
            <form
              @submit.prevent="submit"
              method="post"
              class="bg-theam-secondary"
              enctype="multipart/form-data"
            >
              <div class="modal-body row">
                <div class="col-md-12 pd-r-7-5">
                  <div class="group">
                    <label class="group-label">Rate Change</label>
                    <div class="group-attribute-container">
                      <div class="row mb-2">
                        <div class="col-md-5 mr-0 pr-0">Purchase Rate</div>
                        <div class="col-md-7 ml-0 pl-0">
                          <input
                            type="number"
                            step="any"
                            class="form-control"
                            disabled
                            v-model="formData.purchase_rate"
                          />
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-md-5 mr-0 pr-0">Sales Rate</div>
                        <div class="col-md-7 ml-0 pl-0">
                          <input
                            type="number"
                            step="any"
                            class="form-control"
                            v-model="formData.sales_rate"
                            @keyup="changeDiscountAmount()"
                            @change="changeDiscountAmount()"
                          />
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-md-5 mr-0 pr-0">Discount</div>
                        <div class="col-md-7 ml-0 pl-0">
                          <div class="input-group">
                            <input
                              type="number"
                              step="any"
                              class="form-control"
                              placeholder="0.00"
                              @keyup="discountAmount()"
                              @change="discountAmount()"
                              v-model="formData.sales_discount_percentage"
                            />
                            <div class="percent-sign">%</div>
                            <input
                              type="number"
                              step="any"
                              class="form-control input-disc-per"
                              @keyup="discountPercentage()"
                              @change="discountPercentage()"
                              placeholder="0.00"
                              v-model="formData.sales_discount_amount"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-5 mr-0 pr-0">Net Amount</div>
                        <div class="col-md-7 ml-0 pl-0">
                          <input
                            type="number"
                            step="any"
                            class="form-control"
                            disabled
                            v-model="formData.netAmount"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 text-right mt-3">
                  <button
                    type="button"
                    @click="submitItemDetails()"
                    class="btn btn-sm btn-primary"
                    :disabled="submitting"
                  >
                    <span v-if="!submitting">Submit</span>
                    <span v-else>Submitting...</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "./Services/Service";
export default {
  data() {
    return {
      details: [],
      submitting: false,
      no_data:0,
      formData: {
        purchase_id: "",
        prefix: "",
        purchase_rate: null,
        sales_rate: null,
        sales_discount_amount: null,
        sales_discount_percentage: null,
        total: null,
        netAmount: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataId",
      "modalId",
      "modalMode",
    ]),
    ...mapGetters("stockDetails", [
      "dataSets",
      "item",
      "itemDetails",
      "componentClicked",
    ]),
  },
  beforeUpdate(){
    this.itemDetails.forEach((value) => {
      if(value.mergedItems.length > 0){
        this.no_data =1;
      }
    });
  },
  methods: {
    submit() {
      this.$store.commit("setApiUrl", "api/stocks/rate");
      this.$store.commit("addData", this.formData);
    },
    editRate(details) {
      details["netAmount"] = details.sales_rate - details.sales_discount_amount;
      this.formData = details;
      this.$store.dispatch("modalOpen", "editRate");
    },
    discountAmount() {
      this.formData.sales_discount_amount = this.parseDigit(
        (parseFloat(this.formData.sales_discount_percentage || 0) *
          parseFloat(this.formData.sales_rate || 0)) /
          100
      );
      this.formData.netAmount = this.parseDigit(
        parseFloat(this.formData.sales_rate || 0) -
          parseFloat(this.formData.sales_discount_amount || 0)
      );
    },
    discountPercentage() {
      this.formData.sales_discount_percentage = this.parseDigit(
        (parseFloat(this.formData.sales_discount_amount || 0) /
          parseFloat(this.formData.sales_rate || 0)) *
          100
      );
      this.formData.netAmount = this.parseDigit(
        parseFloat(this.formData.sales_rate) -
          parseFloat(this.formData.sales_discount_amount)
      );
    },
    changeDiscountAmount() {
      if (parseFloat(this.formData.sales_rate || 0) == 0) {
        this.formData.sales_discount_percentage = 0;
      }
      this.discountAmount();
    },
    submitItemDetails() {
      if (this.formValidate()) {
        let id = this.formData.purchase_id;
        this.submitting = true;
        Services.editItemDetails(id, this.formData)
          .then((response) => {
            // Notificaion
            this.setNotification(response.data.success_message);
          })
          .catch((error) => {
            if (error.response.status == 422) {
              let err = Object.values(error.response.data.errors).flat();
              this.setNotification(err);
            } else {
              let err = error.response.data.error_message;
              this.setNotification(err);
            }
          })
          .finally(() => {
            this.submitting = false;
          });
      }
    },
    formValidate() {
      if (this.formData.sales_rate < 0) {
        this.setNotification("Sales Rate cannot be negative.");
        return false;
      }
      return true;
    },
    setNotification(err) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", err);
    },
  },

  watch: {
    dataId(value) {
      this.details = this.dataLists[value];
    },
  },
};
</script>
